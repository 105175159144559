import React, { useEffect } from "react";
import SVCards from "../components/SVCards";
import SVInfoSub from "../components/Usuarios/SVInfoSub";
import { useFilterContext } from "../components/Contexts/FilterProvider";
import { SeasonsList } from "../components/Metadata/Constants.js";

const SVShowsPage = ({ usuario }) => {
  const { filter, setFilter } = useFilterContext();

  useEffect(() => {
    if (!filter) {
      setFilter({
        type: ["T", "M", "I"],
        season: SeasonsList[0],
        title: "",
      });
    }
  }, [filter, setFilter]);

  return (
    <>
      {usuario.isSubscriptionActive && filter ? (
        <SVCards
          title="Obres de Teatre"
          filter={filter}
          saveFilter={setFilter}
        ></SVCards>
      ) : (
        <SVInfoSub />
      )}
    </>
  );
};

export default SVShowsPage;
