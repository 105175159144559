import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { getAuth, signOut } from "firebase/auth";
import SVButtonTermsConditions from "./Usuarios/SVButtonTermsConditions";

function SVPerfil({ usuario, setUsuario }) {
  const handleSignOut = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        setUsuario(null);
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  };

  return (
    <>
      <Container className="mt-2">
        <Row className="mt-2">
          <h2 className="SvTitulo">Perfil del usuari</h2>
          <hr />
        </Row>
        <Row>
          <Col xs={8}>
            <Table size="sm">
              <tbody>
                <tr>
                  <td className="fw-bold">Nom</td>
                  <td>{usuario.firstName + " " + usuario.lastName} </td>
                </tr>
                <tr>
                  <td className="fw-bold">Email</td>
                  <td>{usuario.email}</td>
                </tr>
                <tr>
                  <td className="fw-bold">Subscripció obres de teatre</td>

                  {usuario.isSubscriptionActive ? (
                    <td className="align-middle text-success">Activada</td>
                  ) : (
                    <td className="align-middle">
                      <div className="d-flex align-items-center">
                        <div className="align-middle text-danger me-3">
                          Desactivada
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
              </tbody>
            </Table>
            <Button onClick={handleSignOut} className="mt-4" variant="link">
              SignOut
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <SVButtonTermsConditions />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SVPerfil;
