import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  query,
} from "firebase/firestore/lite";
import moment from "moment";

const SVUsersPage = ({ usuario }) => {
  const [users, setUsers] = useState();

  useEffect(() => {
    const db = getFirestore();
    const queryUsers = query(collection(db, "Users"));
    getDocs(queryUsers).then((res) => {
      const wUsers = [];
      res.forEach((doc) => {
        wUsers.push({
          ...doc.data(),
          id: doc.id,
          activeDate: formatDate(doc.data().activeDate),
        });
      });
      setUsers(wUsers);
    });
  }, []);

  function formatDate(date) {
    return date ? moment(date.toDate()).format("DD/MM/YYYY HH:mm") : "";
  }

  /* const altaCodis = () => {
    const db = getFirestore();
    generarCodigosUnicos(2000).forEach((codi) => {
      setDoc(doc(db, "SubscriptionCodes", codi), {
        id: codi,
        isActived: false,
      }).then(() => {
        console.log(codi);
      });
    });
  };*/

  return (
    <>
      {usuario.isAdmin && users && (
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Nom</th>
              <th scope="col">Email</th>
              <th scope="col">Subscrit</th>
              <th scope="col">Administrador</th>
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {users.map((user) => (
              <tr key={user.id}>
                <th scope="row">{user.firstName + " " + user.lastName} </th>
                <td>{user.email}</td>
                <td>
                  {user.isSubscriptionActive && (
                    <>
                      <i className="bi bi-check-circle-fill iconIsActivated">
                        - {user.activeDate}
                      </i>
                    </>
                  )}
                  {user.isSubscriptionActive === false && (
                    <>
                      <i className="bi bi-check-circle-fill iconIsNotActivated">
                        - {user.activeDate}
                      </i>
                    </>
                  )}
                </td>
                <td>
                  {user.isAdmin && (
                    <i className="bi bi-check-circle-fill iconIsActivated"></i>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {/*usuario.id === "em0DxuHsytTq1ogf0rNK12YrcfQ2" && (
        <button onClick={() => altaCodis()}>Alta codis</button>
      )*/}
    </>
  );
};

export default SVUsersPage;
