import React from "react";
import Container from "react-bootstrap/Container";

function SVInfoSub() {
  return (
    <Container>
      <div className="pt-5 pb-5">
        <div>
          <h2>Activació de la subscripció a Santviflix</h2>
          <p>
            Gràcies per registrar-te a Santviflix! Recorda que l'activació de la
            teva subscripció pot trigar fins a <strong>24 hores</strong> des del
            moment del registre.
          </p>
          <p>
            Si, passat aquest termini, no veus la teva subscripció activada, si
            us plau, assegura't que el correu electrònic que has informat a
            Santviflix coincideix amb el que tens registrat a la{" "}
            <strong>plataforma de gestió de socis de l'entitat</strong>. Pots
            revisar-ho accedint al següent enllaç:
          </p>
          <p>
            <a
              href="https://teatresantvicenc.playoffinformatica.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Plataforma de socis
            </a>
          </p>
          <p>
            Si tens qualsevol dubte o problema, no dubtis a posar-te en contacte
            amb nosaltres a través del correu electrònic:{" "}
            <strong>socissantvicenc@gmail.com</strong>.
          </p>
          <p>Gràcies per la teva confiança!</p>
        </div>
      </div>
    </Container>
  );
}

export default SVInfoSub;
